import { Helmet } from 'react-helmet'
import { useState, useEffect, useMemo } from 'react'
import { useStore } from 'store'
import cx from 'classnames'
import { format } from 'date-fns'

/* Components */
import Button from 'components/button'
import LineDivider from 'components/line-divider'
import RoundRectangleButton from 'components/round-rectangle-button'
import Table from 'components/table/beta'
import { DEFAULT_DATE_FORMAT, DEFAULT_TIME_FORMAT } from 'components/utils/date'
import EntitySelectorFilters, {
  FILTER_TYPE,
} from 'components/entity-selector/filters'
import EntitySelectorActions from 'components/entity-selector/action-bar'
import Modal from 'components/modal'
import useSession from 'modules/session'

import { useIntermediaryStates } from 'hooks/intermediary-states'
import { useSocket } from 'components/utils/socket'

import {
  setFilters,
  getHistory,
  getFilterData,
  exportCsv,
  initiateExportCsv,
} from 'modules/platform-settings/actions'
import {
  showErrorMessage,
  showWarningMessage,
} from 'modules/notifications/actions'

import { ReactComponent as FilterIcon } from 'assets/filters.svg'
import { ReactComponent as DownloadIcon } from 'assets/icon_download.svg'
import { ReactComponent as InfoIcon } from 'assets/icon_info.svg'
import { ReactComponent as CloseIcon } from 'assets/icon_close_white.svg'
import { utils, socket } from '@decision-sciences/qontrol-common'

import '../../data-store-global/style.scss'

const { isEmpty } = utils.object
const { NOTIFICATIONS } = socket

const HISTORY_CSV_DOWNLOAD = 'HISTORY_CSV_DOWNLOAD'

/**
 * Global management module for the data store feature
 * @returns {React.FunctionComponent}
 */
const DataStoreGlobal = () => {
  const { dispatch, state } = useStore()
  const [, user] = useSession()

  const {
    platform: {
      changeHistory: { filters, data, filtersData, count },
    },
  } = state

  const [filtersOpened, setFiltersOpened] = useState(false)
  const [loading, setLoading] = useState(false)
  const [filtersLoading, setFiltersLoading] = useState(false)

  /* This would contain the row for which to render the modal */
  const [modalOpen, setModalOpen] = useState(null)

  const filterActive = filtersOpened || !isEmpty(filters)
  const [appliedFilters, setAppliedFilters] = useState({ ...filters })

  const toggleFiltersOpen = () => setFiltersOpened((prevOpened) => !prevOpened)

  const { getLoadingFor, setLoadingFor } = useIntermediaryStates()

  const onExport = () => {
    if (getLoadingFor(HISTORY_CSV_DOWNLOAD)) {
      return
    }

    showWarningMessage(
      'Qontrol History exports are limited to 90 days going up to the selected End Date',
      dispatch
    )

    setLoadingFor(HISTORY_CSV_DOWNLOAD, true)
    initiateExportCsv({
      ...filters,
      clientTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    }).catch((error) => {
      showErrorMessage(`Unable to export CSV: ${error}`, dispatch)
      setLoadingFor(HISTORY_CSV_DOWNLOAD, false)
    })
  }

  const socket = useSocket({ room: user._id })

  useEffect(() => {
    if (socket?.connected) {
      socket.on(
        NOTIFICATIONS.changeHistoryCsvExport.receive,
        ({ success, date }) => {
          if (!success) {
            setLoadingFor(HISTORY_CSV_DOWNLOAD, false)
            showErrorMessage(
              `Error fetching CSV export. Please try again`,
              dispatch
            )
          } else {
            exportCsv(date)
              .catch((error) =>
                showErrorMessage(`Unable to export CSV: ${error}`, dispatch)
              )
              .finally(() => setLoadingFor(HISTORY_CSV_DOWNLOAD, false))
          }
        }
      )
    }

    return () => {
      socket?.removeAllListeners(NOTIFICATIONS.changeHistoryCsvExport.receive)
    }
  }, [socket?.connected])

  const [paginationData, setPaginationData] = useState({
    pageSize: 10,
    pageIndex: 0,
  })

  const columns = (details = false) => [
    {
      header: 'Date of Change',
      id: 'date',
      accessorKey: 'date',
      cell: (cell) =>
        cell.row.original.date &&
        format(
          new Date(cell.row.original.date),
          `${DEFAULT_DATE_FORMAT} ${DEFAULT_TIME_FORMAT}`
        ),
    },
    {
      header: 'User',
      id: 'user',
      accessorFn: (row) => {
        const emulatedUser =
          row.emulatedUserId && details
            ? ` (emulating ${row.emulatedUserId?.firstName} ${row.emulatedUserId?.lastName})`
            : ''

        return `${row.userId?.firstName} ${row.userId?.lastName}${emulatedUser}`
      },
    },
    {
      header: 'Client',
      id: 'client',
      accessorFn: (row) => (row.global ? 'Global' : row.clientId?.name),
    },
    {
      header: 'Subject Changed',
      id: 'type',
      accessorFn: (row) => row.itemType,
    },
    {
      header: 'Change Details',
      id: 'details',
      cell: ({ cell }) => (
        <InfoIcon
          onClick={() => setModalOpen(cell.row.original)}
          className="history-info-icon"
        />
      ),
      size: 90,
    },
  ]

  useEffect(() => {
    setFiltersLoading(true)
    getFilterData(dispatch)
      .catch((error) =>
        showErrorMessage(
          `Error occurred when getting history filter data: ${error}`,
          dispatch
        )
      )
      .finally(() => setFiltersLoading(false))
  }, [])

  useEffect(() => {
    setLoading(true)
    getHistory(
      dispatch,
      {
        ...filters,
        clientTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
      paginationData.pageIndex,
      paginationData.pageSize
    )
      .catch((error) =>
        showErrorMessage(
          `Error occurred while fetching data: ${error.message || error}`,
          dispatch
        )
      )
      .finally(() => setLoading(false))
  }, [filters, paginationData.pageIndex, paginationData.pageSize])

  const onFilterChange = (fieldKey) => (value) => {
    setAppliedFilters((prevFilters) => {
      if (!prevFilters) {
        return { [fieldKey]: value }
      }
      return { ...prevFilters, [fieldKey]: value }
    })
  }

  const onFilterApply = () => {
    setFilters(dispatch, appliedFilters)
  }

  const onFilterClear = () => {
    setAppliedFilters(null)
    setFilters(dispatch, {})
  }

  const filtering = useMemo(
    () => [
      {
        fieldKey: 'search',
        label: 'Search',
        placeholder: 'Search',
        type: FILTER_TYPE.SEARCH,
      },
      {
        fieldKey: 'dateRange',
        label: 'Date of Change Range',
        fields: [
          {
            fieldKey: 'startDate',
            placeholder: 'Select Date',
            type: FILTER_TYPE.DATE,
          },
          {
            fieldKey: 'endDate',
            placeholder: 'Select Date',
            type: FILTER_TYPE.DATE,
          },
        ],
        type: FILTER_TYPE.CLUSTER,
      },
      {
        fieldKey: 'itemType',
        label: 'Subject',
        placeholder: 'All',
        type: FILTER_TYPE.DROPDOWN_MULTISELECT,
        selectAll: true,
        options: filtersData?.itemTypes || [],
      },
      {
        fieldKey: 'users',
        fields: [
          {
            fieldKey: 'userName',
            label: 'User',
            placeholder: 'All',
            type: FILTER_TYPE.DROPDOWN_MULTISELECT,
            selectAll: true,
            options:
              Object.entries(filtersData?.users || {})?.map(([id, name]) => ({
                value: id,
                label: name,
              })) || [],
          },
          {
            fieldKey: 'userId',
            label: 'User ID',
            placeholder: 'All',
            type: FILTER_TYPE.DROPDOWN_MULTISELECT,
            selectAll: true,
            options:
              Object.keys(filtersData?.users || {})?.map((id) => ({
                value: id,
                label: id,
              })) || [],
          },
        ],
        type: FILTER_TYPE.CLUSTER,
      },
      {
        fieldKey: 'recordId',
        label: 'Record ID',
        placeholder: 'All',
        type: FILTER_TYPE.SEARCH,
      },
      {
        fieldKey: 'client',
        fields: [
          {
            fieldKey: 'client',
            label: 'Client',
            placeholder: 'All',
            type: FILTER_TYPE.DROPDOWN_MULTISELECT,
            selectAll: 'All Clients',
            options:
              Object.entries(filtersData?.clients || {})?.map(([id, name]) => ({
                value: id,
                label: name,
              })) || [],
          },
          {
            fieldKey: 'clientId',
            label: 'Client ID',
            placeholder: 'All',
            type: FILTER_TYPE.DROPDOWN_MULTISELECT,
            selectAll: 'All Client IDs',
            options:
              Object.keys(filtersData?.clients || {})?.map((id) => ({
                value: id,
                label: id,
              })) || [],
          },
        ],
        type: FILTER_TYPE.CLUSTER,
      },
    ],
    [JSON.stringify(filtersData)]
  )

  const _renderDetailsModal = (modalOpen) => {
    return (
      <Modal
        className="history__details-modal"
        heading={
          <div className="display-flex history__details-modal-heading">
            <span>Change Details</span>
            <CloseIcon
              width={16}
              height={16}
              className="history__details-modal-close"
              onClick={() => setModalOpen(null)}
            />
          </div>
        }
        opened={!!modalOpen}
        setOpened={setModalOpen}
      >
        <Table
          columns={columns(true).slice(0, columns.length - 1)}
          showSearchInput={false}
          data={[modalOpen]}
          loading={loading}
        />

        <LineDivider className="history__details-modal__detail-divider" />

        <div className="history__details-modal__detail-label general-label">
          CURRENT VALUE
        </div>
        <div className="history__details-modal__detail">
          {JSON.stringify(modalOpen.currentValue)}
        </div>

        <div className="history__details-modal__detail-label general-label">
          PREVIOUS VALUE
        </div>
        <div className="history__details-modal__detail">
          {JSON.stringify(modalOpen.previousValue)}
        </div>
      </Modal>
    )
  }

  return (
    <div className="align-column data-store-global">
      <Helmet>
        <title>History of Qontrol Changes</title>
      </Helmet>

      {modalOpen ? _renderDetailsModal(modalOpen) : null}

      <div
        data-cy="data-store-dimensions"
        className="margin-top-32 form__section__body dimensions history"
      >
        <div className="align-row align-baseline space-between dimensions__header">
          <h3 className="generic-heading generic-heading--no-margin">
            History of Qontrol Changes
          </h3>
          <div className="align-row">
            <RoundRectangleButton
              onClick={onExport}
              data-cy="history-csv"
              contentRender={() => <DownloadIcon />}
              disabled={getLoadingFor(HISTORY_CSV_DOWNLOAD)}
            />
            <RoundRectangleButton
              onClick={toggleFiltersOpen}
              data-cy="history-filter"
              contentRender={() => (
                <FilterIcon
                  className={cx('filters__icon', {
                    'fill-white': filterActive,
                    'fill-light-blue': !filterActive,
                  })}
                />
              )}
              className={cx('filters__button margin-left-16', {
                'filters__button--active': filterActive,
              })}
            />
          </div>
        </div>
        <LineDivider />
        {filtersOpened && (
          <>
            <EntitySelectorFilters
              filters={filtering}
              selectedFilters={appliedFilters || {}}
              onFiltersChange={onFilterChange}
              loading={filtersLoading}
            />

            <LineDivider />

            <EntitySelectorActions
              hasFilters
              applyFiltersButtonType={null}
              onApplyFilters={onFilterApply}
              actions={
                <Button
                  data-cy="data-store-dimension-bulk-delete"
                  compact
                  red
                  onClick={onFilterClear}
                  value={
                    <div className="align-row gap-10">
                      <CloseIcon width={16} height={16} className="fill-red" />
                      <span>CLEAR FILTERS</span>
                    </div>
                  }
                />
              }
            />

            <LineDivider />
          </>
        )}

        <div className="dimensions__section">
          <div className="dimensions__table">
            <Table
              autoResetPageIndex={false}
              disableSort={true}
              columns={columns(false)}
              paginationValues={[10, 20, 50, 100]}
              showPagination={true}
              showSearchInput={false}
              data={data}
              pageCount={Math.ceil(count / paginationData.pageSize)}
              manualCount={count}
              onChangeTableView={({ pageSize, pageIndex }) => {
                setPaginationData({
                  pageSize,
                  pageIndex,
                })
              }}
              manualPagination={true}
              loading={loading}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default DataStoreGlobal

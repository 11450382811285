import React, { useMemo, useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import isEqual from 'lodash.isequal'

/* Store & Actions */
import { useStore } from 'store'
import {
  exportUsersCsv,
  getUsers,
  resendUserInvite,
} from 'modules/users/actions'

/* Hooks */
import { useAccess, PERMISSION_TYPES, PERMISSIONS } from 'hooks/access'
import useSession from 'modules/session'
import { emulateUser } from 'modules/session/actions'
import { useIntermediaryStates } from 'hooks/intermediary-states'

/* Constants */
import {
  TABLE_CONTAINER,
  defaultFilterSortConfig,
} from 'modules/table-filter-sort/constants'

/* Components */
import { Dropdown } from 'components/dropdown'
import { getTooltipList } from 'components/utils/tooltip'
import Filters from 'components/filters'
import Button from 'components/button'
import Table from 'components/table/beta'
import Input from 'components/input'
import BulkEditUsers from 'modules/users/bulk-edit-users'
import { isUserSetupComplete } from 'components/utils/user'
import RoundRectangleButton from 'components/round-rectangle-button/index'

/* Utils */
import { format } from 'date-fns'
import { showErrorMessage } from 'modules/notifications/actions'
import {
  useBulkEditState,
  useEffectOnUpdate,
} from 'components/utils/custom-hooks'

import {
  resetFilterSort,
  saveFilter,
  saveSort,
} from 'modules/table-filter-sort/actions'

/* Icons */
import blueArrow from 'assets/icon_arrow_blue.svg'
import { ReactComponent as ImportUsersIcon } from 'assets/icon-import-users.svg'
import { ReactComponent as AddUserIcon } from 'assets/icon_plus_blue.svg'
import { ReactComponent as EditIcon } from 'assets/icon_edit.svg'
import { ReactComponent as DownloadIcon } from 'assets/icon_download.svg'
import { entityStatus, utils, user } from '@decision-sciences/qontrol-common'

/* Styles */
import './style.scss'

const { ENTITY_STATUS_WITH_VALUES, ENTITY_STATUS_FOR_FILTERS } = entityStatus
const { compareIgnoreCase } = utils.string
const { getCommonFields } = utils.object
const { getUserStatus } = user

const DEFAULT_PAGE_SIZE = 20
const DEFAULT_PAGINATION = { page: 1, size: DEFAULT_PAGE_SIZE }

const UserManagement = () => {
  const tableContainer = TABLE_CONTAINER.USER_MANAGEMENT
  const { dispatch, state } = useStore()
  const navigate = useNavigate()
  const { list: companies, currentCompany } = state.companies
  const { bulkEditList } = state.users
  const { list: teams } = state.teams
  const [, userData] = useSession()
  const [selectedItems, setSelectedItems] = useBulkEditState(bulkEditList || [])
  const [bulkEdit, setBulkEdit] = useState(bulkEditList.length > 0 || false)

  const filterSort = state.tableFilterSort.filterSort[tableContainer]
  const [loading, setLoading] = useState(false)
  const [, user] = useSession()
  const isAdmin = user.isSuperAdmin
  const currentClientId = currentCompany._id

  const [pageData, setPageData] = useState({ ...DEFAULT_PAGINATION })
  const [total, setTotal] = useState(0)
  const [users, setUsers] = useState([])

  const { setLoadingFor, getLoadingFor } = useIntermediaryStates()

  // Filter out current user
  const userList = useMemo(
    () => users.filter((el) => el._id !== userData._id),
    [JSON.stringify(users)]
  )

  const userSetup = selectedItems[0]
    ? isUserSetupComplete(selectedItems[0])
    : {}

  const hasCreateAccess = useAccess({
    feature: PERMISSIONS.USER_DATA_FORM,
    type: PERMISSION_TYPES.CREATE,
  })
  const hasEditAccess = useAccess({
    feature: PERMISSIONS.USER_DATA_FORM,
    type: PERMISSION_TYPES.EDIT,
  })
  const hasReadAccess = useAccess({
    feature: PERMISSIONS.USER_DATA_FORM,
    type: PERMISSION_TYPES.READ,
  })
  const teamAccess = useAccess({
    feature: PERMISSIONS.TEAMS_INDEX,
    type: PERMISSION_TYPES.READ,
  })
  const companyAccess = useAccess({
    feature: PERMISSIONS.CLIENTS_INDEX,
    type: PERMISSION_TYPES.READ,
  })

  useEffectOnUpdate(() => {
    fetchUsers()
  }, [
    pageData,
    JSON.stringify(filterSort.filter),
    JSON.stringify(filterSort.sort),
  ])

  useEffect(() => {
    if (total < pageData.page * pageData.size) {
      setPageData({ ...DEFAULT_PAGINATION })
    }
  }, [total])

  useEffect(() => {
    return () => {
      getUsers(dispatch, isAdmin ? null : currentClientId)
    }
  }, [])

  const buildQuery = () => {
    const { name, clients, status, teams } = filterSort.filter
    let query = {}

    if (name) {
      query.name = name
    }
    if (clients?.length) {
      query.clients = clients
    }
    if (status) {
      if (status === 'Pending') {
        query.inviteStatus = true
      } else if (status === ENTITY_STATUS_FOR_FILTERS.ARCHIVED) {
        query.deleted = true
      } else {
        query.active = status === 'Active'
      }
    }
    if (teams?.length) {
      query.teams = teams
    }
    // Filter current user
    query.currentUser = user._id
    query = {
      filters: query,
      sorting: filterSort?.sort ? filterSort.sort[0] : {},
    }
    return query
  }

  const buildPagination = () => ({
    page: pageData.page - 1,
    limit: pageData.size,
  })

  const fetchUsers = () => {
    const query = buildQuery()
    const pagination = buildPagination()
    setLoading(true)
    getUsers(dispatch, isAdmin ? null : currentClientId, {
      ...query,
      ...pagination,
    })
      .then(({ list, count }) => {
        setUsers(list)
        setTotal(count)
      })
      .finally(() => setLoading(false))
  }

  const editUser = (id) => {
    navigate(`/users/${id}`)
  }

  const exportUsers = () => {
    setLoadingFor(FIELDS.DOWNLOAD, true)
    exportUsersCsv(currentCompany._id, buildQuery())
      .catch((error) =>
        showErrorMessage(`Unable to export CSV: ${error}`, dispatch)
      )
      .finally(() => setLoadingFor(FIELDS.DOWNLOAD, false))
  }

  let columns = [
    {
      header: 'Name',
      id: 'firstName',
      sortingFn: 'name',
      accessorFn: (row) =>
        row.name ? row.name : `${row.firstName} ${row.lastName}`,
    },
    { header: 'Email', accessorKey: 'email' },
    {
      header: 'Client',
      id: 'clients',
      accessorFn: (row) => {
        if (row.isSuperAdmin) {
          return 'N/A'
        }
        const list =
          companies?.filter(
            (company) =>
              row.clients.findIndex(
                (client) =>
                  client.clientId.toString() === company._id.toString()
              ) !== -1
          ) || []
        if (list.length === 0) {
          return 'N/A'
        }
        if (list.length === 1) {
          return list[0].name
        }
        return 'Multiple'
      },
      tooltip: (row) => {
        if (!row || row.isSuperAdmin) {
          return null
        }
        const list = userData.isSuperAdmin
          ? row.clients.map((c) => c.clientId)
          : row.clients
              .filter(
                (c) =>
                  companies.findIndex(
                    (company) =>
                      company._id.toString() === c.clientId.toString()
                  ) !== -1
              )
              .map((c) => c.clientId)
        if (list.length <= 1) {
          return null
        }
        const userCompanies = list
          .map((id) => {
            return companies && (companies.find((c) => c._id === id) || {}).name
          })
          .sort(compareIgnoreCase)
        return getTooltipList('Companies', userCompanies)
      },
    },
    {
      header: 'Team',
      id: 'teams',
      accessorFn: (row) => {
        if (row.teams.length === 0) {
          return 'N/A'
        }
        if (row.teams.length === 1) {
          return teams?.find((team) => team._id === row.teams[0])?.name
        }
        return 'Multiple'
      },
      tooltip: (row) => {
        if (!row || row.teams.length <= 1 || row.isSuperAdmin) {
          return null
        }

        const teamIdSet = new Set(row.teams)

        const teamList = teams?.filter(({ _id }) => teamIdSet.has(_id)) || []

        return getTooltipList(
          'Teams',
          teamList.map((team) => team.name).sort(compareIgnoreCase)
        )
      },
    },
    {
      header: 'Updated at',
      id: 'updatedAt',
      accessorFn: (row) =>
        format(new Date(row.manuallyUpdatedAt), 'MM-dd-yyyy HH:mm'),
    },
    {
      header: 'Status',
      id: 'active',
      accessorKey: 'active',
      cell: (cell) => {
        const { active, inviteToken, deleted } = cell.row.original
        const value = cell.getValue('active')
        if (inviteToken && active && !deleted) {
          return (
            <div className="align-row align-center resend-invite">
              <span>{value}</span>
              {userData.isSuperAdmin && (
                <img
                  src={blueArrow}
                  alt="resend invite"
                  onClick={() => resendUserInvite(dispatch, cell.row.original)}
                />
              )}
            </div>
          )
        }

        return <span>{value}</span>
      },
      accessorFn: (row) => {
        return getUserStatus(row)
      },
    },
  ]
  hasReadAccess &&
    !bulkEdit &&
    columns.unshift({
      header: '',
      id: 'actions',
      cell: (cell) => (
        <div className="table__actions">
          <div
            className={
              hasEditAccess && !cell.row.original.deleted
                ? 'table__edit'
                : 'table__view'
            }
            onClick={() => editUser(cell.row.original._id)}
          />
        </div>
      ),
      maxSize: 70,
      size: 50,
    })

  // Don't show columns for which the user does not have access
  if (!hasEditAccess) {
    columns = columns.filter((column) => {
      return column.header !== 'Actions' && column.header !== 'Organization'
    })
  }
  if (!teamAccess) {
    columns = columns.filter((column) => column.header !== 'Teams')
  }
  if (!companyAccess) {
    columns = columns.filter((column) => column.header !== 'Companies')
  }

  /** Start emulating a user */
  const onEmulateUser = () => {
    const emulatedUserId = selectedItems[0]?._id
    emulatedUserId && emulateUser(dispatch, emulatedUserId, currentCompany._id)
  }

  const sortChanged = (newSort) => {
    if (!isEqual(newSort, filterSort.sort)) {
      saveSort(dispatch, tableContainer, newSort)
    }
  }

  const onChangeTableView = ({ pageIndex, pageSize, filter, sort }) => {
    if (filter !== null) {
      saveFilter(dispatch, tableContainer, {
        ...filterSort.filter,
        name: filter,
      })
    }
    if (sort) {
      sortChanged(sort)
    }
    setPageData({ page: pageIndex + 1, size: pageSize })
  }

  if (bulkEdit) {
    return (
      <BulkEditUsers
        setBulkEdit={setBulkEdit}
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
        columns={columns}
      />
    )
  }

  return (
    <div className="user-management">
      <Helmet>
        <title>User Management</title>
      </Helmet>
      <div className="heading" data-cy="page-heading">
        User Management
        <div className="heading__buttons">
          {hasEditAccess && Boolean(selectedItems.length) && (
            <Button
              value={
                <div className="import-users-button import-users-button--content">
                  <EditIcon width={16} height={16} />
                  <span>Edit Selected</span>
                </div>
              }
              secondary
              onClick={() => setBulkEdit(true)}
            />
          )}
          {hasCreateAccess && (
            <Button
              value={
                <div className="import-users-button import-users-button--content">
                  <AddUserIcon />
                  <span>Add User</span>
                </div>
              }
              secondary
              onClick={() => navigate('/users/new')}
            />
          )}
          {userData.isSuperAdmin &&
            !userData.emulatedBy &&
            selectedItems.length === 1 && (
              <Button
                value="Emulate User"
                onClick={onEmulateUser}
                secondary={true}
                disabled={!userSetup.isComplete}
                tooltip={userSetup.message}
              />
            )}
          {isAdmin ? (
            <>
              <Button
                value={
                  <div className="import-users-button import-users-button--content">
                    <ImportUsersIcon />
                    <span>Import Users</span>
                  </div>
                }
                onClick={() => navigate('/import-users')}
                secondary
                className={'import-users-button'}
              />
              <RoundRectangleButton
                disabled={getLoadingFor(FIELDS.DOWNLOAD)}
                contentRender={() => <DownloadIcon />}
                className="round-rectangle-button__white"
                onClick={exportUsers}
              />
            </>
          ) : null}
        </div>
      </div>
      <UserFilters
        dispatch={dispatch}
        clients={companies}
        teams={
          teams ? teams.sort((a, b) => compareIgnoreCase(a.name, b.name)) : []
        }
        tableContainer={tableContainer}
        filters={filterSort.filter}
      />
      <Table
        columns={columns}
        data={userList.map((user) => ({
          ...user,
          disableSelect: user.deleted,
        }))}
        nonDeletableEntities={userList.reduce((acc, user) => {
          if (user.deleted) {
            return [...acc, user._id]
          }
          return acc
        }, [])}
        manualPagination
        manualFilter={filterSort?.filter?.name}
        manualCount={Math.ceil(total)}
        pageCount={Math.ceil(total / pageData.size)}
        totalRows={total}
        onChangeTableView={onChangeTableView}
        paginationValues={[DEFAULT_PAGE_SIZE, 50, 100]}
        initialState={{ pageSize: DEFAULT_PAGE_SIZE, sortBy: filterSort.sort }}
        onSelect={hasEditAccess ? setSelectedItems : null}
        loading={loading}
        autoResetPageIndex={false}
        showPagination={true}
        showSearchInput={true}
        onSortChanged={sortChanged}
        tableContainer={tableContainer}
      />
    </div>
  )
}

const initialState = {
  name: '',
  clients: [],
  teams: [],
  status: null,
  inviteStatus: false,
}

const UserFilters = ({ dispatch, clients, teams, tableContainer, filters }) => {
  const initialFilterState =
    defaultFilterSortConfig.filterSort[tableContainer].filter
  const [state, setState] = useState({ ...initialState })

  useEffect(() => {
    setState(filters)
  }, [filters])

  const applyFilters = (state) => {
    saveFilter(dispatch, tableContainer, state)
  }

  const clearFilters = () => {
    resetFilterSort(dispatch, tableContainer)
  }

  const [initial, current] = getCommonFields(initialFilterState, state)
  const [currentState] = getCommonFields(state, current)

  return (
    <Filters
      onApply={() => applyFilters(state)}
      onClear={clearFilters}
      disableClearOnUnmount={true}
      initialApplied={!isEqual(initial, current)}
      initialState={initial}
      currentState={currentState}
    >
      <div>
        {/* Disable Name or Email input until AP-296 is done */}
        <Input
          onChange={(value) => setState({ ...state, name: value })}
          value={state.name ? state.name : ''}
          placeholder="Name or Email"
          disabled={false}
        />
      </div>

      <Dropdown
        defaultOptionText="Clients"
        options={clients?.map((o) => ({ label: o.name, value: o._id }))}
        selectedItems={state.clients}
        multiSelect
        selectAll
        showAsFilters
        onChange={(clients) => setState({ ...state, clients })}
      />

      <Dropdown
        defaultOptionText="Teams"
        options={teams?.map((t) => ({ label: t.name, value: t._id }))}
        selectedItems={state.teams}
        multiSelect
        selectAll
        showAsFilters
        onChange={(teams) => setState({ ...state, teams })}
      />
      <Dropdown
        defaultOptionText="Status"
        options={[
          ...ENTITY_STATUS_WITH_VALUES,
          { label: 'Pending', value: 'Pending' },
          { label: 'Archived', value: ENTITY_STATUS_FOR_FILTERS.ARCHIVED },
        ]}
        defaultState={state.status}
        deselectLabel="Status"
        onChange={(status) => {
          setState({ ...state, status })
        }}
      />
    </Filters>
  )
}

const FIELDS = {
  DOWNLOAD: 'DOWNLOAD',
}

UserFilters.propTypes = {
  dispatch: PropTypes.func.isRequired,
  clients: PropTypes.array.isRequired,
  teams: PropTypes.array,
  tableContainer: PropTypes.string.isRequired,
  filters: PropTypes.object.isRequired,
}

export default UserManagement

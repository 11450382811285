import React, { useState, useEffect, useMemo, useRef } from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import { notifications } from '@decision-sciences/qontrol-common'
import { ALERTS_CONTAINER_DROPDOWN } from '@decision-sciences/qontrol-common/src/alert'
import { useNavigate, useParams, redirect } from 'react-router-dom'
import cx from 'classnames'
import { useStore } from 'store'
import useSession from 'modules/session'

import { NOT_FOUND_ROUTE } from 'routes'

import AlertStartTime from 'modules/alerts/alert-start-time'
import { getGranularitiesOfAlert } from 'modules/traq-templates/actions'
import { getAlertCategories } from 'modules/alerts/actions'

import Modal from 'components/modal'
import Input from 'components/input'
import { FIELD_TYPES, validate } from 'components/validator'
import StickyFooter from 'components/sticky-footer'
import useLeaveConfirm from 'components/leave-confirm'
import Table from 'components/table/beta'
import { Dropdown } from 'components/dropdown'
import { getTooltipList } from 'components/utils/tooltip'
import Loader from 'components/loader'
import Icon from 'components/icon/index'

import { useAccess, PERMISSION_TYPES, PERMISSIONS } from 'hooks/access'
import { useOnClickOutside } from 'hooks/outside-click'

import { ReactComponent as RemoveIcon } from 'assets/icon_remove_circle_outline.svg'

import './styles.scss'
import {
  createUpdateNotificationGroup,
  getNotificationGroup,
  getNotificationGroups,
} from './actions'
import SelectAlertModalContent from './select-alert-modal-content'

const { FREQUENCY_OPTIONS } = notifications

// Remove first element from frequency options (Minutes)
const frequencyWithoutMinutes = [...FREQUENCY_OPTIONS]
frequencyWithoutMinutes.shift()

/**
 * Notification Group Create/Edit form
 * @returns {React.Component}
 */
const CreateEditNotificationGroup = ({ onDelete }) => {
  const { key } = useParams()
  const navigate = useNavigate()
  const [, user] = useSession()
  const {
    dispatch,
    state: { notificationGroups, alerts, companies },
  } = useStore()

  const allNotificationGroups = notificationGroups?.list
  const allAlertCategories = alerts?.categories
  const [loading, setLoading] = useState(true)
  const [errors, setErrors] = useState({})
  const [setDirty, LeaveConfirm] = useLeaveConfirm({})
  const [openAlertModal, setOpenAlertModal] = useState(false)

  const isNew = key === 'new'

  useEffect(() => {
    if (!allAlertCategories) {
      getAlertCategories(
        dispatch,
        user.isSuperAdmin ? null : companies?.currentCompany._id
      )
    }
  }, [JSON.stringify(allAlertCategories), companies?.currentCompany])

  useEffect(() => {
    if (!allNotificationGroups) {
      getNotificationGroups(dispatch)
    }
  }, [JSON.stringify(allNotificationGroups), companies?.currentCompany])

  const hasReadAccess = useAccess({
    feature: PERMISSIONS.NOTIFICATION_GROUPS,
    type: PERMISSION_TYPES.READ,
  })
  const hasCreateAccess = useAccess({
    feature: PERMISSIONS.NOTIFICATION_GROUPS,
    type: PERMISSION_TYPES.CREATE,
  })
  const hasEditAccess = useAccess({
    feature: PERMISSIONS.NOTIFICATION_GROUPS,
    type: PERMISSION_TYPES.EDIT,
  })
  const hasDeleteAccess = useAccess({
    feature: PERMISSIONS.NOTIFICATION_GROUPS,
    type: PERMISSION_TYPES.DELETE,
  })
  const viewOnly = useMemo(
    () => !isNew && !hasEditAccess && !hasCreateAccess,
    [isNew, hasEditAccess]
  )

  useEffect(() => {
    if (!hasReadAccess) {
      redirect('/unauthorized')
    }
  }, [hasReadAccess])

  /** Default State */
  const defaultState = {
    name: '',
    groupType: '',
    alerts: [],
    startTime: null,
  }
  const [state, setState] = useState(defaultState)

  const [recurrenceFrequency, setRecurrenceFrequency] = useState(
    state?.alerts?.[0]?.alert?.recurrence?.frequency || null
  )

  useEffect(
    () =>
      setRecurrenceFrequency(state?.alerts?.[0]?.alert?.recurrence?.frequency),
    [state?.alerts?.[0]?.alert?.recurrence?.frequency]
  )

  /** If the notification group was not found in local store, fetch it */
  useEffect(() => {
    if (!isNew && !state?._id && key) {
      setLoading(true)
      const foundState = notificationGroups?.list?.find(
        (item) => item._id.toString() === key.toString()
      )

      if (foundState) {
        setState({
          ...foundState,
        })
      } else {
        setLoading(true)
        getNotificationGroup(key).then((res) => {
          if (!res) {
            return navigate(NOT_FOUND_ROUTE, true)
          }
          setState({ ...res })
        })
      }
    }

    setLoading(false)
  }, [key])

  /** Validate & Save handler */
  const onSave = (e) => {
    e.preventDefault()

    // Validate
    setLoading(true)
    const [isValid, errors] = validate(ERROR_MAP, state)

    if (!isValid) {
      setLoading(false)
      return setErrors(errors)
    }

    createUpdateNotificationGroup(dispatch, state)
      .then((res) => {
        if (!res.success) {
          setErrors({
            ...errors,
            general: res.error?._message || 'Something went wrong',
          })
        } else {
          setDirty(false)

          // If success, redirect back to list page
          setTimeout(() => {
            navigate('/notification-groups')
          }, 100)
        }
      })
      .catch((err) => {
        if (typeof err === 'object') {
          setErrors({ ...errors, ...err })
        } else {
          setErrors({ ...errors, general: err })
        }
      })
      .finally(() => setLoading(false))
  }

  /** Edit Name field */
  const editName = (name) => {
    setDirty(true)
    setState({ ...state, name })
    errors.name && setErrors({ ...errors, name: null })
  }

  const editGroupType = (groupType) => {
    setDirty(true)

    // Remove all alerts from other groupType
    if (groupType !== state.groupType) {
      setState({ ...state, alerts: [], groupType })
    } else {
      setState({ ...state, groupType })
    }
    errors.groupType && setErrors({ ...errors, groupType: null })
  }

  const editStartTime = (startTime) => {
    if (state.startTime) {
      setDirty(true)
    }
    setState({ ...state, startTime })
    errors.startTime && setErrors({ ...errors, startTime: null })
  }

  const reassignAlertOrder = (alerts) =>
    alerts?.map(({ alert }, index) => ({
      alert,
      order: index + 1,
    }))

  const editAlerts = (alerts) => {
    /** Reassign order */
    const newAlerts = reassignAlertOrder(alerts)

    setDirty(true)
    setState({ ...state, alerts: newAlerts })
    setErrors({ ...errors, alerts: null })
  }

  const removeAlert = (idToRemove) => {
    setState({
      ...state,
      alerts: reassignAlertOrder(
        state.alerts.filter((alert) => alert?.alert?._id !== idToRemove)
      ),
    })
  }

  const reorderAlerts = (originalOrder, newOrder) => {
    /** We're working with 0 indexed arrays */
    originalOrder--
    newOrder--

    const newArray = [...state.alerts]

    newArray.splice(newOrder, 0, newArray.splice(originalOrder, 1)[0])

    editAlerts(reassignAlertOrder(newArray))
  }

  const limitWidth = window.screen.width <= 1440 ? 40 : 100

  const alertTableColumns = [
    {
      header: '',
      id: 'actions',
      cell: (cell) => {
        if (viewOnly) {
          return null
        }
        return (
          <Icon
            onClick={() => {
              !viewOnly && removeAlert(cell.row.original.alert._id)
            }}
          >
            <RemoveIcon />
          </Icon>
        )
      },
      size: 20,
      minSize: 40,
      maxSize: 42,
    },
    {
      header: 'Alert Name',
      accessorFn: (row) =>
        `${row.alert?.name?.substring(0, limitWidth)}${
          row.alert?.name?.length >= limitWidth ? '...' : ''
        }`,
      tooltip: (row) => {
        return row.alert?.name?.length >= limitWidth
          ? getTooltipList('Alert Name', [row.alert?.name])
          : null
      },
      size: 200,
    },
    {
      header: 'Alert Category',
      accessorFn: (row) => {
        return (
          row.alert?.category?.name ||
          allAlertCategories?.find((cat) => cat._id === row.alert?.category)
            ?.name
        )
      },
    },
    {
      header: 'Frequency',
      accessorFn: (row) => {
        const time = row.alert?.recurrence
        return time ? `Every ${time.amount} ${time.frequency}` : ''
      },
      size: 100,
    },
    {
      header: 'Granularity',
      accessorFn: (row) => {
        const alertGranularities = getGranularitiesOfAlert(row?.alert)
        if (alertGranularities?.length > 1) {
          return 'Multiple'
        } else {
          return alertGranularities?.[0]
        }
      },
      tooltip: (row) => {
        const alertGranularities = getGranularitiesOfAlert(row?.alert)
        return alertGranularities?.length > 1
          ? alertGranularities.join(', \n')
          : null
      },
      size: 100,
    },
    {
      header: 'Order',
      accessorFn: (row) =>
        state?.alerts?.find((alert) => alert?.alert?._id === row.alert?._id)
          ?.order,
      size: 20,
      maxSize: 40,
    },
  ]

  if (state?.alerts?.length) {
    alertTableColumns.push({
      header: '',
      id: 'orderDropdown',
      cell: (cell) =>
        orderDropdown(
          cell,
          state.alerts.length,
          reorderAlerts,
          /** If the item is in the lower rows, open the dropdown options upwards so they don't get clipped under the table */
          cell.row.original.order > state.alerts.length / 2
        ),
      size: 20,
      maxSize: 40,
    })
  }

  if (loading) {
    return <Loader />
  }

  return (
    <>
      {openAlertModal ? (
        <Modal
          opened={openAlertModal}
          heading="Add Alerts to Group"
          className="flow-modal duplicate-campaign-modal"
        >
          <SelectAlertModalContent
            onCloseX={() => {
              setOpenAlertModal(false)
            }}
            state={state}
            setState={editAlerts}
            recurrenceFrequency={recurrenceFrequency}
            setRecurrenceFrequency={setRecurrenceFrequency}
          />
        </Modal>
      ) : null}
      <LeaveConfirm />
      <form
        onSubmit={onSave}
        className={`form notification-groups-edit ${
          loading ? 'form--loading' : ''
        }`}
      >
        <Helmet>
          <title>
            {!isNew ? 'Edit Notification Group' : 'Create Notification Group'}
          </title>
        </Helmet>
        {/* Header */}
        <div className="heading" data-cy="page-heading">
          {!isNew ? 'Edit Notification Group' : 'Create Notification Group'}
        </div>
        {errors.general ? <div className="error">{errors.general}</div> : null}

        <section className="form__section">
          <div className="form__section__body">
            <div className="form__row">
              {!viewOnly ? (
                <>
                  <Input
                    placeholder="Enter Name"
                    label="Group Name"
                    value={state?.name}
                    onChange={editName}
                    error={errors.name}
                    className="form__half input-wrapper--uppercase"
                  />
                  <Dropdown
                    defaultOptionText="Select your Group type"
                    className="form__half input-wrapper--uppercase"
                    defaultState={state?.groupType}
                    error={errors.groupType}
                    options={ALERTS_CONTAINER_DROPDOWN}
                    label="Group Type"
                    onChange={editGroupType}
                  />
                </>
              ) : (
                <>
                  <div className="form__half">
                    <p className="general-label">Group Name</p>
                    <p className="general-name">{state?.name || ''}</p>
                  </div>
                  <div className="form__half">
                    <p className="general-label">Group Type</p>
                    <p className="general-name">{state?.groupType || ''}</p>
                  </div>
                </>
              )}
            </div>
          </div>
        </section>

        <section className="form__section">
          <p className="section-header">Select Alerts</p>
          <div className="form__section__body">
            <p className="general-label">Grouped alerts</p>

            {state?.alerts?.length ? (
              <>
                <p className="info">
                  Place the selected alerts in the order you want them to be
                  sent and displayed when triggered.
                </p>
                <Table
                  withDragDrop={!viewOnly}
                  disableSort
                  onDragDrop={editAlerts}
                  columns={alertTableColumns}
                  data={state?.alerts || []}
                  dragNDropIconIsLastColumn
                />
              </>
            ) : null}
            {!viewOnly && (
              <div
                className="align-to-right"
                onClick={() => {
                  setOpenAlertModal(!openAlertModal)
                }}
              >
                + Select Alerts
              </div>
            )}
          </div>
        </section>

        <section className="form__section">
          <p className="section-header">Group Start Time</p>
          <div className="form__section__body">
            <p className="info">
              The start time selected here will override the start time
              established for each selected alert within this group.
            </p>
            <AlertStartTime
              defaultValue={state?.startTime}
              onChange={editStartTime}
              recurrence={{ frequency: recurrenceFrequency }}
            />
          </div>
        </section>

        <StickyFooter
          buttons={[
            {
              value: !isNew ? 'Save Changes' : 'Save Notification Group',
              onClick: onSave,
              disabled: loading,
              renderCondition: !viewOnly,
            },
            {
              value: 'Cancel',
              onClick: () => navigate('/notification-groups'),
              secondaryGray: true,
            },
            {
              value: 'Delete Group',
              onClick: () => onDelete(state),
              type: 'secondaryRed',
              disabled: loading,
              renderCondition: !isNew && hasDeleteAccess,
            },
          ]}
        />
      </form>
    </>
  )
}

/**
 * Dropdown that helps reorder the alerts
 * @param {Object} cell table cell
 * @param {Number} nrOfAlerts number of alerts
 * @param {Function} reorderAlerts function that reorders alerts
 * @param {Boolean} [listOpensUpwards] if true, the dropdown list would open upwards
 * @returns {React.Component}
 */
const orderDropdown = (
  cell,
  nrOfAlerts,
  reorderAlerts,
  listOpensUpwards = false
) => {
  const alertId = cell.row.original.alert?._id

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [open, setOpen] = useState(false)

  const orderOptions = [...Array(nrOfAlerts || 0).keys()]
    .map((n) => n + 1)
    .filter((n) => n !== cell.row.original.order)

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const ref = useRef()
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useOnClickOutside(ref, () => setOpen(false))

  return (
    <div
      className="notification-groups__ordering"
      onClick={() => setOpen(!open)}
      ref={ref}
      key={alertId}
      tabIndex={1}
    >
      <div
        className={cx('notification-groups__ordering-options', {
          'notification-groups__ordering-options--show': open,
          'notification-groups__ordering-options--upwards': listOpensUpwards,
        })}
      >
        {orderOptions.map((number) => (
          <div
            key={`${alertId}-${number}`}
            className="notification-groups__ordering-item"
            onClick={() => reorderAlerts(cell.row.original.order, number)}
          >
            {number}
          </div>
        ))}
      </div>
    </div>
  )
}

CreateEditNotificationGroup.propTypes = {
  key: PropTypes.string,
  onDelete: PropTypes.func.isRequired,
}

const ERROR_MAP = {
  name: FIELD_TYPES.REQUIRED,
  groupType: FIELD_TYPES.REQUIRED,
  startTime: FIELD_TYPES.REQUIRED,
}

export default CreateEditNotificationGroup

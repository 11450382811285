import React from 'react'
import { Helmet } from 'react-helmet'
import { NavLink, Outlet } from 'react-router-dom'
import { useStore } from 'store'

// Icons
import { ReactComponent as IconSlackColor } from 'assets/icon-slack-colors.svg'
import { ReactComponent as IconSecuritySetting } from 'assets/icon-security-settings.svg'
import { ReactComponent as IconOfflineData } from 'assets/icon-offline-data.svg'
import { ReactComponent as IconNamingConventions } from 'assets/icon-naming-conventions.svg'
// import { ReactComponent as IconGlobalReports } from 'assets/icon-global-reports.svg'
import { ReactComponent as IconDataStore } from 'assets/icon-data-store.svg'
import { ReactComponent as IconDataCleanup } from 'assets/icon-data-cleanup.svg'
import { ReactComponent as IconHistory } from 'assets/icon_history.svg'

import './global-settings.scss'

const GLOBAL_SETTINGS_ROUTES = (SHOW_DATA_STORE_GLOBAL) => ({
  security: {
    key: 'security',
    name: 'Security Settings',
    icon: <IconSecuritySetting />,
  },
  'slack-colors': {
    key: 'slack-colors',
    name: 'Slack Colors',
    icon: <IconSlackColor />,
  },
  // 'global-reports': {
  //   key: 'global-reports',
  //   name: 'Global Reports',
  //   icon: <IconGlobalReports />,
  // },
  'daily-entity-cleanup': {
    key: 'daily-entity-cleanup',
    name: 'Data Cleanup',
    icon: <IconDataCleanup />,
  },
  'naming-conventions': {
    key: 'naming-conventions',
    name: 'Naming Conventions',
    icon: <IconNamingConventions />,
  },
  'offline-data': {
    key: 'offline-data',
    name: 'Offline Data',
    icon: <IconOfflineData />,
  },
  'change-history': {
    key: 'change-history',
    name: 'History',
    icon: <IconHistory />,
  },
})

const GlobalSettings = () => {
  const {
    state: {
      global: {
        environment: { SHOW_DATA_STORE_GLOBAL },
      },
    },
  } = useStore()

  return (
    <section className="global-settings">
      <Helmet>
        <title>Global Settings</title>
      </Helmet>
      <div className="heading" data-cy="page-heading">
        Global Settings
      </div>
      <div className="global-settings__tabs">
        {Object.values(GLOBAL_SETTINGS_ROUTES(SHOW_DATA_STORE_GLOBAL)).map(
          ({ key, name, hidden, icon }) => {
            if (typeof hidden === 'boolean' && hidden) {
              return null
            }
            return (
              <NavLink
                key={key}
                to={`/global-settings/${key}`}
                className="global-settings__tab"
              >
                {icon}
                {name}
              </NavLink>
            )
          }
        )}
      </div>
      <section className="global-settings__content">
        <Outlet />
      </section>
    </section>
  )
}

export default GlobalSettings

import React, { useState, useEffect, useMemo } from 'react'
import propTypes from 'prop-types'
import cx from 'classnames'

import Modal from 'components/modal/index'
import Button from 'components/button/index'
import ButtonToggle from 'components/button-toggle/index'
import { Dropdown } from 'components/dropdown/index'
import LineDivider from 'components/line-divider/index'
import InputGroup, { InputGroupArrows } from 'components/input-group/index'
import Icon from 'components/icon/index'
import InputText from 'components/input/index'
import {
  AccountDropdownRow,
  AccountInformation,
} from 'components/account-icon/index'
import WarningIcon from 'components/warning-icon/index'
import InformationBlock from 'components/information-block/index'

import { ReactComponent as IconCheck } from 'assets/icon_check_no_border.svg'
import { ReactComponent as DeleteIcon } from 'assets/icon_table_remove.svg'

import {
  checkExistingClientId,
  checkExistingName,
} from 'modules/companies/actions'

import { CREATE_BU_OPTIONS } from 'modules/companies/constants'

import './style.scss'

const DEFAULT_CREATE_BU_OPTIONS = CREATE_BU_OPTIONS.START_FROM_SCRATCH

const BusinessUnitCopyConfirmation = ({
  opened,
  onConfirm,
  onCancel,
  businessUnits = [],
}) => {
  const [createBUOption, setCreateBUOption] = useState(
    DEFAULT_CREATE_BU_OPTIONS
  )
  const [selectedBU, setSelectedBU] = useState()
  const [newBUsToBeCreated, setNewBUsToBeCreated] = useState([
    { name: '', accounts: [] },
    { name: '', accounts: [] },
  ])
  const [errors, setErrors] = useState({})
  const [showUnassignedAccountsModal, setShowUnassignedAccountsModal] =
    useState(false)

  const buAccounts = useMemo(() => {
    return (
      businessUnits
        .find(({ _id }) => _id === selectedBU)
        ?.accounts.map(({ externalAccountId: accountId, name, type, key }) => ({
          accountName: name,
          accountId,
          id: accountId,
          key,
          label: name,
          name,
          type,
          value: key,
        })) || []
    )
  }, [businessUnits, selectedBU])

  const selectedAccounts = useMemo(() => {
    return newBUsToBeCreated.reduce(
      (acc, currBU) => [...acc, ...currBU.accounts],
      []
    )
  }, [newBUsToBeCreated])

  // Ensure the value is set to default on any modal change
  useEffect(() => {
    setCreateBUOption(DEFAULT_CREATE_BU_OPTIONS)
    setNewBUsToBeCreated([
      { name: '', accounts: [] },
      { name: '', accounts: [] },
    ])
    setSelectedBU(null)
    setErrors({})
    setShowUnassignedAccountsModal(false)
  }, [opened])

  const addNewBU = () => {
    const newValue = [...newBUsToBeCreated]
    const bu = selectedBU
      ? businessUnits.find(({ _id }) => _id === selectedBU)
      : null

    let clientId = ''
    if (bu) {
      const existingIds = new Set(
        newBUsToBeCreated.map(({ clientId }) => clientId)
      )
      const nextAvailableId = newBUsToBeCreated
        .map((_bu, index) => `${bu.clientId}-S${index + 1}`)
        .find((clientId) => !existingIds.has(clientId))

      clientId =
        nextAvailableId || `${bu.clientId}-S${newBUsToBeCreated.length + 1}`
    }

    newValue.push({ name: '', accounts: [], clientId })
    setNewBUsToBeCreated(newValue)
  }

  const removeNewBU = (idx) => {
    const newValue = [...newBUsToBeCreated]
    const newErrors = { ...errors }
    for (let i = idx; i < Object.keys(newErrors).length - 1; i++) {
      newErrors[i] = newErrors[i + 1]
    }
    delete newErrors[Object.keys(newErrors).length - 1]
    newValue.splice(idx, 1)
    setNewBUsToBeCreated(newValue)
    setErrors(newErrors)
  }

  const validate = async () => {
    let isValid = true
    if (createBUOption === CREATE_BU_OPTIONS.SPLIT_BU) {
      const newErrors = {}
      const newBUNames = newBUsToBeCreated.map(({ name }) => name)
      const duplicateNames = newBUNames.filter(
        (name, index) => name && newBUNames.indexOf(name) !== index
      )

      const newClientIds = newBUsToBeCreated.map(({ clientId }) => clientId)
      const duplicateIds = newClientIds.filter(
        (clientId, index) =>
          clientId && newClientIds.indexOf(clientId) !== index
      )

      newBUsToBeCreated.forEach((newBU, idx) => {
        newErrors[idx] = {}
        if (!newBU.name) {
          newErrors[idx].emptyName = true
          isValid = false
        }
        if (duplicateNames.includes(newBU.name)) {
          newErrors[idx].duplicateName = true
          isValid = false
        }
        if (!newBU.accounts.length) {
          newErrors[idx].accounts = true
          isValid = false
        }
        if (!newBU.clientId) {
          newErrors[idx].emptyId = true
          isValid = false
        }
        if (duplicateIds.includes(newBU.clientId)) {
          newErrors[idx].duplicateId = true
          isValid = false
        }
      })

      if (isValid) {
        const existingBuNames = await Promise.all(
          newBUNames.map((name) => checkExistingName({ name, _id: selectedBU }))
        )
        existingBuNames.forEach((value, idx) => {
          if (value) {
            newErrors[idx].existingName = true
            isValid = false
          }
        })
        const existingBuIds = await Promise.all(
          newClientIds.map((clientId) =>
            checkExistingClientId({ clientId, _id: selectedBU })
          )
        )
        existingBuIds.forEach((value, idx) => {
          if (value) {
            newErrors[idx].existingId = true
            isValid = false
          }
        })
      }

      if (isValid && buAccounts.length !== selectedAccounts.length) {
        setShowUnassignedAccountsModal(true)
        isValid = false
      }
      setErrors(newErrors)
    }
    return isValid
  }

  const _renderNewBURows = () => {
    return newBUsToBeCreated.map((bu, idx) => {
      return (
        <InputGroup
          className="split-to"
          key={idx}
          options={[
            {
              render: (
                <InputText
                  id={idx}
                  placeholder="Enter Business Unit Name"
                  value={bu.name}
                  onChange={(value) => {
                    const newSplitTo = [...newBUsToBeCreated]
                    newSplitTo[idx] = { ...newSplitTo[idx], name: value }
                    setErrors({
                      ...errors,
                      [idx]: {
                        ...errors[idx],
                        emptyName: false,
                        duplicateName: false,
                        existingName: false,
                      },
                    })
                    setNewBUsToBeCreated(newSplitTo)
                  }}
                  error={
                    errors[idx]?.emptyName ||
                    errors[idx]?.duplicateName ||
                    errors[idx]?.existingName
                  }
                />
              ),
              width: 300,
              label: idx === 0 ? 'Business Unit Name' : null,
              error:
                errors[idx]?.emptyName ||
                errors[idx]?.duplicateName ||
                errors[idx]?.existingName,
            },
            {
              render: (
                <InputText
                  id={idx}
                  placeholder="Enter Business Unit ID"
                  value={bu.clientId}
                  onChange={(value) => {
                    const newSplitTo = [...newBUsToBeCreated]
                    newSplitTo[idx] = { ...newSplitTo[idx], clientId: value }
                    setErrors({
                      ...errors,
                      [idx]: {
                        ...errors[idx],
                        emptyId: false,
                        duplicateId: false,
                        existingId: false,
                      },
                    })
                    setNewBUsToBeCreated(newSplitTo)
                  }}
                  error={
                    errors[idx]?.emptyId ||
                    errors[idx]?.duplicateId ||
                    errors[idx]?.existingId
                  }
                />
              ),
              width: 300,
              label: idx === 0 ? 'Business Unit Id' : null,
              error:
                errors[idx]?.emptyId ||
                errors[idx]?.duplicateId ||
                errors[idx]?.existingId,
            },
            {
              render: (
                <Dropdown
                  defaultOptionText="Select Accounts"
                  selectAll={true}
                  multiSelect={true}
                  hasSearch={true}
                  displaySearchInOptions={true}
                  selectedItems={newBUsToBeCreated[idx]?.accounts}
                  onChange={(value) => {
                    const newSplitTo = [...newBUsToBeCreated]
                    newSplitTo[idx] = {
                      ...newSplitTo[idx],
                      accounts: value,
                    }
                    errors[idx]?.accounts &&
                      setErrors({
                        ...errors,
                        [idx]: { ...errors[idx], accounts: false },
                      })
                    setNewBUsToBeCreated(newSplitTo)
                  }}
                  optionRenderer={(option, selectedItems) => (
                    <AccountDropdownRow
                      option={option}
                      selectedItems={selectedItems}
                    />
                  )}
                  options={buAccounts.filter(({ key }) =>
                    !newBUsToBeCreated[idx].accounts.includes(key)
                      ? !selectedAccounts.includes(key)
                      : true
                  )}
                  sortOptions
                  disabled={!selectedBU}
                  error={errors[idx]?.accounts}
                />
              ),
              width: 302,
              label: idx === 0 ? 'Accounts' : null,
              error: errors[idx]?.accounts,
            },
            {
              render: (
                <Icon
                  onClick={() => {
                    removeNewBU(idx)
                  }}
                  disabled={newBUsToBeCreated.length === 2}
                >
                  <DeleteIcon />
                </Icon>
              ),
              width: 34,
            },
          ]}
        />
      )
    })
  }

  return (
    <Modal
      opened={opened}
      contentSeparator={true}
      heading={'Add Business Unit'}
      className="bu-copy-confirmation"
      button={
        <Button
          data-cy="client-bu-confirmation_confirm"
          onClick={async () => {
            const validation = await validate()

            if (validation) {
              onConfirm(
                createBUOption,
                selectedBU,
                newBUsToBeCreated,
                selectedAccounts
              )
            }
          }}
          value={
            <div className="confirm-button">
              <IconCheck /> Confirm
            </div>
          }
          secondaryGreen
        />
      }
      buttonSecondary={
        <Button
          data-cy="client-bu-confirmation_cancel"
          onClick={() => {
            onCancel()
          }}
          value="Cancel"
          secondaryGray
        />
      }
      rightAlignButtons
    >
      <p>
        Select to start from scratch, copy client settings, duplicate a business
        unit, or split the business unit into multiple BUs
      </p>
      <div className="modal__children__new-bu-options">
        <ButtonToggle
          data-cy="client-bu-confirmation_button_scratch"
          label="Start BU from scratch"
          disabled={createBUOption === CREATE_BU_OPTIONS.SPLIT_BU && selectedBU}
          selected={createBUOption === CREATE_BU_OPTIONS.START_FROM_SCRATCH}
          onClick={() =>
            setCreateBUOption(CREATE_BU_OPTIONS.START_FROM_SCRATCH)
          }
        />
        <ButtonToggle
          data-cy="client-bu-confirmation_button_copy"
          label="Copy Client to BU"
          selected={createBUOption === CREATE_BU_OPTIONS.COPY_CLIENT}
          onClick={() => {
            setCreateBUOption(CREATE_BU_OPTIONS.COPY_CLIENT)
          }}
          disabled={createBUOption === CREATE_BU_OPTIONS.SPLIT_BU && selectedBU}
        />
        <ButtonToggle
          data-cy="client-bu-confirmation_button_duplicate"
          label="Duplicate BU"
          selected={createBUOption === CREATE_BU_OPTIONS.DUPLICATE_BU}
          onClick={() => {
            setSelectedBU(null)
            setCreateBUOption(CREATE_BU_OPTIONS.DUPLICATE_BU)
          }}
          disabled={
            !businessUnits.length ||
            (createBUOption === CREATE_BU_OPTIONS.SPLIT_BU && selectedBU)
          }
        />
        <ButtonToggle
          data-cy="client-bu-confirmation_button_split"
          label="Split BU"
          selected={createBUOption === CREATE_BU_OPTIONS.SPLIT_BU}
          onClick={() => {
            setSelectedBU(null)
            setCreateBUOption(CREATE_BU_OPTIONS.SPLIT_BU)
          }}
          disabled={!businessUnits.length}
        />
      </div>
      {createBUOption === CREATE_BU_OPTIONS.DUPLICATE_BU && (
        <>
          <LineDivider />
          <Dropdown
            label="Business Unit"
            labelClassName="general-label"
            defaultOptionText="Select Business Unit"
            hasSearch={true}
            options={businessUnits.map(({ _id, name }) => ({
              value: _id,
              label: name,
            }))}
            defaultState={selectedBU}
            onChange={(value) => {
              setSelectedBU(value)
            }}
          />
        </>
      )}
      {createBUOption === CREATE_BU_OPTIONS.SPLIT_BU && (
        <>
          <LineDivider />
          <p>
            Select the business unit to split and how many business units to
            split it into
          </p>
          <div className="modal__children__split-from">
            <Dropdown
              className="modal__children__split-from__drop-down"
              label="Business Unit"
              labelClassName="general-label"
              defaultOptionText="Select Business Unit"
              hasSearch={true}
              options={businessUnits.map(({ _id, name }) => ({
                value: _id,
                label: name,
              }))}
              defaultState={selectedBU}
              onChange={(value) => {
                const selectedBu = businessUnits.find(
                  ({ _id }) => _id === value
                )
                const newBUs = newBUsToBeCreated.map((bu, index) => ({
                  name: '',
                  accounts: [],
                  clientId: `${selectedBu.clientId}-S${index + 1}`,
                }))
                setNewBUsToBeCreated(newBUs)
                setSelectedBU(value)
              }}
            />
            <InputGroup
              options={[
                {
                  render: (
                    <div className="modal__children__split-from__nr-of-bus">
                      {newBUsToBeCreated.length}
                    </div>
                  ),
                  width: 198,
                },
                {
                  render: (
                    <InputGroupArrows
                      onClick={(incrementor) =>
                        incrementor === 1
                          ? addNewBU()
                          : removeNewBU(newBUsToBeCreated.length - 1)
                      }
                      disabledDown={newBUsToBeCreated.length === 2}
                      disabledUp={newBUsToBeCreated.length === 10}
                    />
                  ),
                  width: 26,
                },
              ]}
            />
          </div>
          <LineDivider />
          <p>
            Name the new business units and select the accounts to associate to
            each of them
          </p>
          <div
            className={cx('new-bu-rows-container', {
              'new-bu-rows-container__overflow': newBUsToBeCreated.length > 4,
            })}
          >
            {_renderNewBURows()}
          </div>
          <div className="bu-copy-confirmation__error-container">
            {Object.values(errors).some(({ emptyName }) => emptyName) && (
              <InformationBlock info="Business Unit name must be entered" />
            )}
            {Object.values(errors).some(
              ({ duplicateName }) => duplicateName
            ) && <InformationBlock info="Duplicate business unit names" />}
            {Object.values(errors).some(({ accounts }) => accounts) && (
              <InformationBlock info="At least one account must be associated with a business unit" />
            )}
            {Object.values(errors).some(({ existingName }) => existingName) && (
              <InformationBlock info="Business unit names already exist" />
            )}
            {Object.values(errors).some(({ emptyId }) => emptyId) && (
              <InformationBlock info="Business Unit id can't be left empty" />
            )}
            {Object.values(errors).some(({ existingId }) => existingId) && (
              <InformationBlock info="Business unit ids already exist" />
            )}
            {Object.values(errors).some(({ duplicateId }) => duplicateId) && (
              <InformationBlock info="Duplicate Business Unit Id" />
            )}
          </div>
        </>
      )}
      <Modal
        opened={showUnassignedAccountsModal}
        contentSeparator={true}
        heading={
          <>
            Unassigned Accounts
            <Icon className="margin-left-auto">
              <WarningIcon />
            </Icon>
          </>
        }
        className="bu-copy-confirmation__unassigned-accounts-modal"
        button={
          <Button
            data-cy="client-bu-confirmation__unassigned-accounts_confirm"
            onClick={() => {
              onConfirm(
                createBUOption,
                selectedBU,
                newBUsToBeCreated,
                selectedAccounts
              )
            }}
            value={
              <div className="confirm-button">
                <IconCheck /> Confirm
              </div>
            }
            secondaryGreen
          />
        }
        buttonSecondary={
          <Button
            data-cy="client-bu-confirmation__unassigned-accounts_cancel"
            onClick={() => {
              setShowUnassignedAccountsModal(false)
            }}
            value="Cancel"
            secondaryGray
          />
        }
        rightAlignButtons
      >
        <p>
          The accounts below from the original BU have not been assigned to the
          split BUs. If you continue, those accounts will no longer be assigned
          to a BU.
        </p>
        <div className="unassigned-accounts-list">
          {buAccounts
            .filter(({ key }) => !selectedAccounts.includes(key))
            .map(({ name, type, accountId }) => {
              return (
                <AccountInformation
                  key={accountId}
                  accountName={name}
                  accountType={type}
                  externalAccountId={accountId}
                />
              )
            })}
        </div>
        <p>
          Click the Confirm button to continue with your action. This action
          cannot be undone.
        </p>
      </Modal>
    </Modal>
  )
}

BusinessUnitCopyConfirmation.propTypes = {
  opened: propTypes.bool,
  onConfirm: propTypes.func.isRequired,
  onCancel: propTypes.func.isRequired,
  businessUnits: propTypes.array,
}

export default BusinessUnitCopyConfirmation
